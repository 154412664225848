import React, { useState, useEffect } from 'react';
import {Container} from '@mui/material';

import Alerts from '../components/Alerts';
import DataGrid from '../components/DataGrid';

import {EndpointList, UpdateSim} from '../services/Endpoints';

export default function Endpoint() {
  const [listEndpoints, setListEndpoints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("all");
  const [alertDetails, setAlertDetails] = useState({ open:false, text:undefined });

  useEffect(() => {
    if(localStorage.length === 0){ window.location.href = '/'; }
    
    async function componentDidMount(){
      await getListEndpoints(filterStatus);
    }
    componentDidMount();
  }, []);

  /* gets the list of endpoints ...
  Parameters:
   -simStatus: 1=Activated 2=Suspended */
  const getListEndpoints = async(simStatus) => {
    setListEndpoints(await EndpointList(simStatus));
    setIsLoading(false);
  }
  /* Activated or Suspendedperforms the SIM.
  Parameters:
    -simID: id of the sim to edit.
    -newStatus: 1=Activated 2=Suspended */
  const changeSimStatus = async(simID, newStatus) => {
    await UpdateSim(simID, newStatus);
    updateData("Changes saved");
  }

  const filterChange = async(status) => {
    setFilterStatus(status);
    setIsLoading(true);
    await getListEndpoints(status);
  };
  const updateData = async(text) => {
    setIsLoading(true);
    await getListEndpoints(filterStatus);
    setAlertDetails({ open:true, text:text });
    setTimeout(()=>setAlertDetails({ open:false, text:undefined }), 7000);
  }

  return (
    <div className="backPage" >
      <Alerts alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
      
      <Container maxWidth={false} className="containerPage">
        <DataGrid 
          height={650}
          table="endpoints"
          row={listEndpoints} 
          isLoading={isLoading} 
          filterStatus={filterStatus} 
          changeStatus={changeSimStatus} 
          updateData={updateData} 
          filterChange={filterChange}
        />
      </Container>
    </div>
  );
}
