
import axios from 'axios';

let auth_token = null, tag = null;

export function Logout(){
   localStorage.clear(); 
   window.location.href = '/';
}

/* fetches the list of edpoints filtered by the tag which is the company name
parameters
 -nameSearched: specific name to be searched with
 -currentPage: page from which you want to fetch list of endpoints */
export async function EndpointList (filterStatus) {
   let newList=[]; 
   auth_token = localStorage.getItem('auth_token').toString();
   tag = localStorage.getItem('tag').toString();
   if(filterStatus === "all"){filterStatus="";} 
   else{filterStatus= "%2Cstatus%3A"+filterStatus;}
   
   let response = await EndpointPageNumberValidation(tag,filterStatus,1,auth_token,newList);
   newList = response.newList;
   if(response.totalPages>1){
      for (let i = 2; i <= response.totalPages; i++) {
         let response = await EndpointPageNumberValidation(tag,filterStatus,i,auth_token,newList);
         newList = response.newList;
      }
   }

   return newList;
}
async function EndpointPageNumberValidation (tag,filterStatus,currentPage,auth_token,newList) {
   let totalPages;
   try {
      await axios.get(`https://cdn.emnify.net/api/v1/endpoint?q=tags%3A${tag+filterStatus}&sort=id&page=${currentPage}`, { headers: { Authorization: auth_token } } )
      .then((response) => { 
         response.data.map(
         function createList(element){ //list is created with the specific data to be used from the endpoint list
           return ( 
            newList.push({
               "id": element.id,
               "name": element.name,
               "statusSIM": element.sim.status,
               "iccid": element.sim.iccid,
               "msisdn":element.sim.msisdn,
               "imei": element.imei,
               "simID": element.sim.id,
            })
           );
         }
      );
      totalPages=response.headers['x-total-pages'];
      });
   } 
   catch (err) { Logout(); } 

   return {newList,totalPages};
}

/* Update the status of a SIM by ID.
Activated/Suspended the sim, using the EMnify API.
parameters: 
   - simID: id of the sim to be edited.
   - status: id of the sim status (1=Activated 2=Suspended).  */
export async function UpdateSim(simID, status ) {
   let body ={
      "status": {
         "id": status
      }
   }

   try {  await axios.patch(`https://cdn.emnify.net/api/v1/sim/${simID}`, body, { headers: { Authorization: auth_token } } ); } 
   catch (err) { Logout(); }
}

/* Update name or status(reset) of a endpoint by ID.
parameters: 
   - endpointID: id of the endpoint to be edited.
   - updatedata: array with field and value to update
      status: 0=Enabled 1=Disabled
      name: name of the endpoint  */
export async function UpdateEndpoint(endpointID, updatedata) {
   let body ={}

   if(updatedata[0] === "status"){
      body ={
         "status": {
            "id": updatedata[1]
         }
      }
   }
   else{
      body ={
         "name": updatedata[1]
      }
   }

   try {  await axios.patch(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}`, body, { headers: { Authorization: auth_token } } ); } 
   catch (err) { Logout(); }
}


//DASHBOARD

/* retorna los consumos del mes actual, en base al ID de la SIM using the EMnify API*/
export async function CurrentMonthUsage (simID) { 
   let dataUsage={};
   try {
     dataUsage = (await axios.get(`https://cdn.emnify.net/api/v1/sim/${simID}/stats`, { headers: { Authorization: auth_token } } )).data.current_month.data;
   } catch (err) {}

  return dataUsage; 
}

/* returns a list of events by client (tag) using the EMnify API */
export async function ListEvents () {
   let events=[];

   let response = await EventsPageNumberValidation(tag,1,events);
   events = response.events;
   if(response.totalPages>1){
      for (let i = 2; (i <= response.totalPages && i < 6); i++) {
         let response = await EventsPageNumberValidation(tag,i,events);
         events = response.events;
      }
   }

   return events;
}
async function EventsPageNumberValidation (tag, currentPage, events) {
   let totalPages;
   try {
       await axios.get(`https://cdn.emnify.net/api/v1/event?sort=-timestamp&q=tags%3A${tag.toLowerCase()}&per_page=500&page=${currentPage}`, { headers: { Authorization: auth_token } } )
       .then((response) => {
           response.data.map(
               async function createList(element){//list is created with the specific data to be used from the events
               let date = new Date(element.timestamp);
               return ( 
                   await events.push({
                   "id": element.id,
                   "simID": element.sim.id,
                   "endpointID": element.endpoint.id,
                   "endpointName": "My SIM - "+element.sim.id,//element.endpoint.name,
                   "severity": element.event_severity.description,
                   "date": date.toLocaleString(),
                   "eventSource": element.event_source.description,
                   "eventType": element.event_type.description,
                   "network": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : element.detail.name,
                   "country": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : element.detail.country.name,
                   })
               );
               }
           );
           totalPages=response.headers['x-total-pages'];
       });
   } 
   catch (err) { Logout(); } 

   return {events,totalPages};
}


//FUNCTION OF DETAILS

/* fetches and returns the connectivity data of an endpoint by its ID, using the EMnify API
parameter:
   -endpointID: id of the endpoint to edit. */
export async function ConnectivityData (endpointID) {
   let connectivityData={};
   try {
      connectivityData = (await axios.get(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/connectivity`, { headers: { Authorization: auth_token } } )).data;
   } catch (err) { Logout(); }

   return connectivityData; 
}

/* fetch sim details like status, imie, iccid, endpoint name, using EMnify API.
parameters:
-simID: id of the sim you want details of. */
export async function SIMDetails (simID) {
   let simDetails={};
   try {
      await axios.get(`https://cdn.emnify.net/api/v1/sim/${simID}`, { headers: { Authorization: auth_token } } )
      .then((response) => { 
         simDetails = {
            "id": response.data.endpoint.id, 
            "statusID": response.data.status.id, 
            "status": response.data.status.description, 
            "name": response.data.endpoint.name, 
            "iccid": response.data.iccid, 
            "imei": response.data.endpoint.imei, 
            "msisdn": response.data.msisdn, 
         }
      })
   } catch (err) { Logout(); }

   return simDetails;
}

/* fetch the data usage details of an sim according to its id, using the EMnify API.
parameter:
   -simID: id of the simID to edit.*/
export async function DataUsage (simID, month) {
   let dataUsage=[];
   let dates= await DateFormat(month);
   auth_token = localStorage.getItem('auth_token').toString();
   tag = localStorage.getItem('tag').toString();

   try {
     await axios.get(`https://cdn.emnify.net/api/v1/sim/${simID}/stats/daily?start_date=start_date%3D${dates.start_date}&end_date=end_date%3D${dates.end_date}`, { headers: { Authorization: auth_token } } )
    .then((response) => { 
         response.data.map(
            async function createList(element){ //list is created with the specific data to be used from the graphs
              return ( 
               await dataUsage.unshift({
                  "day": element.date.substr(8,2),
                  "totalMB": element.data.volume > 0? parseFloat(element.data.volume.toFixed(2)) : element.data.volume, 
                  "MBup": element.data.volume_tx > 0? parseFloat(element.data.volume_tx.toFixed(2)) : element.data.volume_tx,
                  "MBdown": element.data.volume_rx > 0? parseFloat(element.data.volume_rx.toFixed(2)) : element.data.volume_rx,
                  "totalSMS": element.sms.volume, 
                  "SMSmt": element.sms.volume_tx,
                  "SMSmo": element.sms.volume_rx,
               })
              );
            }
         );
      });
   } 
   catch (err) { Logout(); }

   return dataUsage; 
}
function DateFormat(month){
   let date = new Date();
   let start_date = (new Date(date.getFullYear(), date.getMonth(), 1)).toISOString();
   let end_date = (new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString();
   if(month === "last"){
      start_date = (new Date(date.getFullYear(), date.getMonth() - 1, 1)).toISOString();
      end_date = (new Date(date.getFullYear(), date.getMonth(), 0)).toISOString();
   }
   
   start_date = start_date.slice(0,-14);
   end_date = end_date.slice(0,-14);
   
   return { start_date, end_date };
}

/* returns a list of events of an endpoint according to its Id using the EMnify API
parameter:
   -endpointID: id of the endpoint. 
   -currentPage: page number from get data will be retrieved*/
export async function ListEndpointEvents (endpointID) {
   let list=[];
   
   let response = await EventPageNumberValidation(endpointID,1,list);
   list = response.newList;
   if(response.totalPages>1){
      for (let i = 2; (i <= response.totalPages && i < 6); i++) {
         let response = await EventPageNumberValidation(endpointID,i,list);
         list = response.newList;
      }
   }

   return list;
}
async function EventPageNumberValidation (endpointID, currentPage, newList) {
   let totalPages;
   try {
      await axios.get(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/event?sort=-timestamp%2Cid&per_page=500&page=${currentPage}`, { headers: { Authorization: auth_token } } )
      .then((response) => { 
         response.data.map(
            async function createList(element){//list is created with the specific data to be used from the events
               let date = new Date(element.timestamp);
              return ( 
               await newList.push({
                  "id": element.id,
                  "severity": element.event_severity.description,
                  "date": date.toLocaleString(),
                  "eventSource": element.event_source.description,
                  "eventType": element.event_type.description,
                  "network": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : element.detail.name,
                  "country": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : element.detail.country.name,
                  "endpointName": element.endpoint.name,
               })
              );
            }
         );
         totalPages=response.headers['x-total-pages'];
      });
   } 
   catch (err) { Logout(); } 

   return {newList,totalPages};
}

/* returns a list of SMS of an endpoint according to its Id using the EMnify API
parameter:
   -endpointID: id of the endpoint. */
export async function ListEndpointSMS (endpointID) {
   let list=[];
   try {
      list = (await axios.get(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/sms`, { headers: { Authorization: auth_token } } )).data;
      await list.sort(function (a, b) {
         if (a.id > b.id) {
           return 1;
         }
         if (a.id < b.id) {
           return -1;
         }
         // a must be equal to b
         return 0;
      });
   } catch (err) { Logout(); }
   return list;
}

/* cancel message according to your id, using the EMnify API
parameters:
   -endpointID: id of the edpoint the message belongs to.
   -smsID: id of the sms to cancel */
export async function CancelSMS (endpointID, smsID) {
   try {
      await axios.delete(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/sms/${smsID}`, { headers: { Authorization: auth_token } } )
   } catch (err) { Logout(); }
}

/* send sms to endpoint according to its id,using the EMnify API
parameters:
-endpointID: id of the endpoint to which the sms will be sent.
-messageText: text of the message to be sent */
export async function SendSMS (endpointID, messageText) {
   let body ={
      "source_address": "+0000000000",
      "payload": messageText
   }

   try {
      await axios.post(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/sms`,body, { headers: { Authorization: auth_token } } )
   } catch (err) { Logout(); }
}

   