import React, {useState,useEffect} from 'react';
import {useParams,useLocation} from 'react-router-dom';
import {Container} from '@mui/material';

import Alerts from '../components/Alerts';
import DetailsAndUsage from '../components/DetailsAndUsage';
import DataGrid from '../components/DataGrid';
import ResetPopUp from '../components/ResetPopUp';
import SMSPopUp from './SMSPopUp';

import { SIMDetails, ConnectivityData, UpdateSim, UpdateEndpoint, DataUsage, ListEndpointEvents} from '../services/Endpoints';

export default function EndpointDetails() {
  const params = useParams(); const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [alertDetails, setAlertDetails] = useState({ open:false, text:undefined });
  const [simDetails, setSimDetails] = useState({});
  const [connectivityData, setConnectivityData] = useState({});
  const [dataUsage, setDataUsage] = useState({});
  const [listEvents, setListEvents] = useState([]);
  const [openReset, setOpenReset] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);

  const handleClose = () => {
    setOpenReset(false); 
    setOpenSMS(false); 
  };
  
  useEffect(() => {
    if(localStorage.length === 0){ window.location.href = '/'; }
    
    async function componentDidMount(){
      await getDataUsage("current");
      await getConnectivityData();
      await getSIMDetails();
      await getListEvents();
    }
    componentDidMount();
    const interval = setInterval(async() => {
      await getConnectivityData();
      await getListEvents();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  /* fget the details of the sim the endpoint belongs to by sim id
  and assigns it to the state simDetails */
  const getSIMDetails = async() => {
    setSimDetails(await SIMDetails(state?.simID));
  }
  /* fetches the connectivity data according to the endpoint's id
  and assigns it to the state connectivityData */
  const getConnectivityData = async() => {
    setConnectivityData(await ConnectivityData(params.id));
    setTimeout(()=>setIsLoading(false), 3000);
  }
  /* an array with the consumption data of the current month is fetched, using the function where the EMnify API is implemented.
  Once the information is fetched, the array format is restructured and sorted from lowest to highest in terms of dates. */
  const getDataUsage = async(month) => {
    let data = await DataUsage(state?.simID,month)
    let totals = data.shift();
    setDataUsage({totals: totals, data:data});
  } 
  /* fetches the list of events of an endpoint by its id, 
  calling the method that contains the EMnify api and assigns to the state listEvents
    -selectedPage: page number from get data will be retrieved */
  const getListEvents = async()=> {
    setListEvents(await ListEndpointEvents(params.id));
  }
  /* edits the name of the endpoint by its id. 
  The getSIMDetails, getConnectivityData, getListEvents methods are called to update the data. 
  parameters:
    -newName: the new name to be assigned to the endpoint. */ 
  const changeName = async(newName) => {
    await UpdateEndpoint(params.id, ["name", newName]);
    updateData("Changes saved");
  }
  /* Activated or Suspendedperforms the SIM.
  Parameters:
    -simID: id of the sim to edit.
    -newStatus: 1=Activated 2=Suspended */
  const changeSimStatus = async() => {
    let newStatus=0;
    if(simDetails.statusID === 1) newStatus = 2;
    else newStatus = 1;
    await UpdateSim(state?.simID, newStatus);
    updateData("Changes saved");
  }

  const updateData = async(text) => {
    setIsLoading(true);
    await getConnectivityData();
    await getListEvents();
    await getSIMDetails();
    
    setAlertDetails({ open:true, text:text });
    setTimeout(()=>setAlertDetails({ open:false, text:undefined }), 7000);
  }

  return (
    <div className="backPage" >
      <Alerts alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
      
      <DetailsAndUsage 
        //Edpoint Details
        isLoading={isLoading}
        simDetails={simDetails} 
        connectivityData={connectivityData} 
        updateData={updateData} 
        changeStatus={changeSimStatus}
        changeName={changeName}
        //Graph
        dataUsage={dataUsage}
        updateGraph={getDataUsage}
        //SMS & Reset
        setOpenSMS={()=>setOpenSMS(true)} 
        setOpenReset={()=>setOpenReset(true)}
      />

      <Container maxWidth={false} className="containerPage" sx={{ mb: '90px' }}>
        <DataGrid 
          height={560}
          table="events"
          row={listEvents} 
        />
      </Container>
      
      <ResetPopUp open={openReset} handleClose={handleClose} updateData={updateData} endpointID={simDetails.id}/>
      <SMSPopUp open={openSMS} handleClose={handleClose} endpointData={simDetails}/>
  </div>
  );
}
